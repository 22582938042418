html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  
}

.whatsapp-container{
  position: absolute;
 
}

.whatsapp {
  height: 140px;
  width: 100px;
  margin-left: 1400px;
  position: fixed;
  right: 10rem;
  bottom: 0;
  display: flex;
  justify-content: flex-end
}

@media (min-width: 3072px) {
  .whatsapp {
    margin-top: 1820px;
    margin-left: 2900px;
  }
}

@media (max-width: 768px) {
  .whatsapp {
    display: none;
  }
}
